/* eslint-disable react/display-name */
import type { ForwardedRef } from "react";
import { forwardRef } from "react";
import { Link } from "react-router-dom";

interface RouterLinkProps
  extends Omit<React.ComponentPropsWithoutRef<typeof Link>, "to"> {
  href: string;
}

// ----------------------------------------------------------------------

const RouterLink = forwardRef(
  (
    { href, ...other }: RouterLinkProps,
    ref: ForwardedRef<HTMLAnchorElement>
  ) => {
    return <Link ref={ref} to={href} {...other} />;
  }
);

export default RouterLink;
