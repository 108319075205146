/* eslint-disable react/no-children-prop */
/* eslint-disable no-unsafe-optional-chaining */
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Drawer from "@mui/material/Drawer";
// import Button from '@mui/material/Button';
import { alpha } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Collapse, IconButton } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// import { getNavConfig } from './utils';
import Scrollbar from "../../components/scrollbar/scrollbar";
import { usePathname } from "src/routes/hooks/use-pathname";
import { useResponsive } from "src/hooks/use_responsive";
import { navConfigures } from "./config-navigation";
import { NAV } from "./config-layout";
import RouterLink from "src/routes/components/router-link";

// ----------------------------------------------------------------------

export default function Nav({ openNav, onCloseNav }) {
  const pathname = usePathname();
  const navConfigs = navConfigures["organization"];
  const upLg = useResponsive("up", "lg", "");

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
  }, [pathname]);

  const renderAccount = (
    <Box
      sx={{
        my: 3,
        mx: 2.5,
        py: 1.5,
        px: 2.5,
        display: "flex",
        borderRadius: 4,
        alignItems: "center",
        bgcolor: "rgba(70, 166, 255, 0.25)", // account box bgColor background color
        color: "#737373",
      }}
    >
      <Box sx={{ ml: 2 }}>
        <Typography variant="subtitle2">First Name</Typography>

        <Typography variant="body2" sx={{ color: "#737373" }}>
          Organization
        </Typography>
      </Box>
    </Box>
  );

  const renderMenu = (
    <Stack component="nav" spacing={0.5} ml={2}>
      {navConfigs &&
        navConfigs?.map((item) => (
          <NavItem key={item} item={item} children={item.children} />
        ))}
    </Stack>
  );

  const renderContent = (
    <Scrollbar
      sx={{
        minHeight: "100%",
        overflow: "auto",
        borderRadius: "0px 24px 24px 0px",
        height: 1,
        "& .simplebar-content": {
          minHeight: "100%",
          height: "auto",
          display: "flex",
          flexDirection: "column",
          background: "#fff", // side bar background color
        },
      }}
    >
      {renderAccount}

      {renderMenu}

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <Box
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.WIDTH },
        backgroundColor: "rgba(70, 166, 255, 0.25)",
        boxShadow: 4,
      }}
    >
      {upLg ? (
        <Box
          sx={{
            height: "100vh",
            position: "fixed",
            background: "#fff", // side bar background color
            width: NAV.WIDTH,
          }}
        >
          {renderContent}
        </Box>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.WIDTH,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

// ----------------------------------------------------------------------

const ExpandMore = styled((props) => {
  // eslint-disable-next-line no-unused-vars
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

function NavItem({ item }) {
  const { children } = item;
  const pathname = usePathname();
  const [expanded, setExpanded] = useState(false);
  const hasChildren = Array.isArray(children);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const active =
    item.path === pathname ||
    (item.children && item.children.some((child) => child.path === pathname));

  return (
    <>
      <ListItemButton
        expand={expanded}
        onClick={() => handleExpandClick()}
        aria-expanded={expanded}
        aria-label="show more"
        component={RouterLink}
        href={item.path}
        sx={{
          width: "100%",
          minHeight: 36,
          // borderRadius: 0.75,
          typography: "body2",
          color: "#737373", // sidebar text color of item
          textTransform: "capitalize",
          fontWeight: "fontWeightMedium",
          ...(active && {
            color: "primary.light", // sidebar color of item on active
            fontWeight: "fontWeightSemiBold",
            bgcolor: "rgba(70, 166, 255, 0.25)", // sidebar bgcolor of item on active
            "&:hover": {
              bgcolor: "rgba(70, 166, 255, 0.25)",
              color: "#737373",
            },
          }),
        }}
      >
        <Box component="span" sx={{ width: 18, height: 18, mr: 2 }}>
          {item.icon}
        </Box>

        <Box component="span" sx={{ fontWeight: "300", fontSize: "13px" }}>
          {item.title}{" "}
        </Box>
        {hasChildren && (
          <ExpandMore
            sx={{ padding: "0px", color: "#737373" }}
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        )}
      </ListItemButton>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {children?.map((i) => (
          <ListItemButton
            key={i}
            component={RouterLink}
            href={i.path}
            sx={{
              marginLeft: "12px",
              minHeight: 36,
              borderRadius: 0.75,
              typography: "body2",
              color: "#737373",
              textTransform: "capitalize",
              fontWeight: "fontWeightMedium",
              "&:hover": {
                bgcolor: (theme) => alpha(theme.palette.primary.main, 0.16),
              },
            }}
          >
            <Box component="span" sx={{ width: 22, height: 22, mr: 1 }}>
              {i.icon}
            </Box>

            <Box component="span">{i.title} </Box>
          </ListItemButton>
        ))}
      </Collapse>
    </>
  );
}

NavItem.propTypes = {
  item: PropTypes.object,
  CurrentTheme: PropTypes.object,
};
