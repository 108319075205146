/* eslint-disable react/prop-types */
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

import { NAV, HEADER } from "./config-layout";
import { useResponsive } from "src/hooks/use_responsive";

// ----------------------------------------------------------------------

const SPACING = 8;

export default function Main({ children, sx, ...other }) {
  const lgUp = useResponsive("up", "lg");

  return (
    <>
      <Box
        component="main"
        sx={{
          bgcolor: "rgba(70, 166, 255, 0.25)",
          flexGrow: 1,
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          py: `${HEADER.H_MOBILE + SPACING}px`,
          ...(lgUp && {
            px: 2,
            py: `${HEADER.H_DESKTOP + SPACING}px`,
            width: `calc(100% - ${NAV.WIDTH}px)`,
          }),
          ...sx,
        }}
        {...other}
      >
        {children}
      </Box>
      <Box
        sx={{
          background: "transparent",
          color: "#455a64",
          bottom: "0rem",
          display: "flex",
          alignItems: "center",
          position: "fixed",
          height: "2.5rem",
          width: "100%",
          left: { xs: "0px", lg: `calc(0px + ${NAV.WIDTH}px)` },
          ...(lgUp && {
            width: `calc(100% - ${NAV.WIDTH}px)`,
          }),
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            padding: "0px 4px",
          }}
        >
          <Typography sx={{ fontSize: "12px" }}>
            © 2024
            {/* {fDate(new Date())} */}| Zimio Technologies LLC | All rights
            reserved.
          </Typography>
          <Typography x={{ fontSize: "12px" }}>version 1.8</Typography>
        </Box>
      </Box>
    </>
  );
}

Main.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.object,
};
