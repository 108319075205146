import { Suspense } from "react";
import { Outlet, useRoutes } from "react-router-dom";
import CRMLayout from "src/layouts/crm/CRMLayout";
import { HomePage, ProductsPage } from "src/pages";

export default function Router() {
  const routes = useRoutes([
    {
      path: "/",
      element: (
        <CRMLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </CRMLayout>
      ),
      children: [
        {
          index: true,
          element: <HomePage />,
        },

        { path: "canva/dashboard", element: <HomePage /> },
        { path: "canva/templates", element: <ProductsPage /> },
      ],
    },
  ]);

  return routes;
}
