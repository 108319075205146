import PropTypes from "prop-types";
import { useState } from "react";

import Box from "@mui/material/Box";

import Nav from "./nav";
import Main from "./main";
import Header from "./header";

// ----------------------------------------------------------------------

export default function CRMLayout({ children }) {
  const [openNav, setOpenNav] = useState(false);

  return (
    <>
      <>
        <Header onOpenNav={() => setOpenNav(true)} />

        <Box
          sx={{
            minHeight: 1,
            display: "flex",
            flexDirection: { xs: "column", lg: "row" },
          }}
        >
          <Nav openNav={openNav} onCloseNav={() => setOpenNav(false)} />

          <Main>{children}</Main>
        </Box>
      </>
    </>
  );
}

CRMLayout.propTypes = {
  children: PropTypes.node,
};
