// ----------------------------------------------------------------------

export const navConfigures = {
  organization: [
    {
      title: "dashboard",
      path: "/canva/dashboard",
    },
    {
      title: "templates",
      path: "/canva/templates",
    },
  ],
};
