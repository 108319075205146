import { Box, Card, CardContent, Grid, Typography } from "@mui/material";

import { ConnectButton, ConnectionAlert, DeveloperNote } from "src/components";
import { useAppContext } from "src/context";

export const HomePage = () => {
  const { displayName } = useAppContext();

  return (
    <>
      <ConnectionAlert />
      <Typography color="#737373" variant="h4" gutterBottom={true}>
        {displayName ? `Good day, ${displayName}!` : `Good day!`}
      </Typography>
      <HomeTiles />
    </>
  );
};

const HomeTiles = () => (
  <Grid
    container={true}
    spacing={2}
    alignItems="stretch"
    direction="row"
    justifyContent="stretch"
  >
    <Grid item={true} xs={4} display="flex">
      <ConnectToCanvaCta />
    </Grid>
  </Grid>
);

export const ConnectToCanvaCta = () => {
  return (
    <Card sx={{ minWidth: 275, paddingX: 5, paddingY: 2 }}>
      <CardContent
        sx={{
          height: "100%",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        <Typography
          color="#737373"
          variant="h6"
          align="center"
          gutterBottom={true}
        >
          Connect to Canva
        </Typography>
        <Box paddingY={6}>
          <Typography
            variant="caption"
            align="center"
            paragraph={true}
            gutterBottom={true}
            color="#737373"
          >
            Connect the Canva for <b>Zim Doc</b> integration to seamlessly
            manage existing assets, templates, and create designs at scale.
          </Typography>
          <Box display="flex" justifyContent="center">
            <ConnectButton />
          </Box>
        </Box>
        <Box display="flex" justifyContent="center">
          <DeveloperNote info="Please Request to product owner" />
        </Box>
      </CardContent>
    </Card>
  );
};
