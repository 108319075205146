import { Box, Card, CardMedia, Stack, Typography } from "@mui/material";
import { CanvaIcon, DemoButton } from "src/components";
import type { DesignProduct } from "src/models/design";

export const ProductCard = ({
  product,
  onClick,
}: {
  product: DesignProduct;
  onClick: () => void;
}) => (
  <Card
    variant="outlined"
    sx={{
      height: "300px",
      display: "flex",
      flexDirection: "column",
      border: "none",
      position: "relative",
      backgroundColor: "#E1E4E7",
      "&:hover .overlayButton": {
        opacity: 1,
        background: (theme) => theme.palette.background.paper,
      },
    }}
  >
    <Box position="relative">
      <CardMedia
        component="img"
        image={product?.thumbnail?.url}
        alt={product.title}
        sx={{
          borderRadius: 3,
          marginBottom: 2,
          objectFit: "contain",
          width: "100%",
          height: "200px",
        }}
      />
      <DemoButton
        demoVariant="primary"
        onClick={onClick}
        className="overlayButton"
        startIcon={<CanvaIcon />}
        sx={{
          position: "absolute",
          top: 8,
          right: 8,
          opacity: 0,
          background: (theme) => theme.palette.background.default,
          transition: "opacity 0.3s ease",
          "&:hover": {
            background: (theme) => theme.palette.background.default,
            color: (theme) => theme.palette.primary.light,
          },
          "&:focus": { opacity: 1 },
        }}
      >
        EDIT IN CANVA
      </DemoButton>
    </Box>
    <Stack spacing={1}>
      <Typography color="#737373" variant="h6">
        {product.title}
      </Typography>
    </Stack>
  </Card>
);
