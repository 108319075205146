import { Box, Typography } from "@mui/material";

export const PageHeader = ({ title }: { title: string }) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      marginBottom={2}
    >
      <Typography color="#737373" variant="h4">
        {title}
      </Typography>
    </Box>
  );
};
